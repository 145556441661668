import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Common/Loader";
import { routes } from "../../constants";
import { login } from "../../services/ApiService";
import Logo from "../../assets/images/logo/main_logo.png";
import Beer from "../../assets/images/footer-images/beer.png";
import UnderMaintenance from '../../assets/images/common-images/under_maintenance.png';
import { getIsLoggedIn, DappUI } from "@elrondnetwork/dapp-core";
import Sound from "../../assets/sounds/welcome.mp3";
import {
  isMobile,
  browserName,
  isAndroid,
  isIOS,
  isChrome,
  isSafari,
} from "react-device-detect";
import "./style.css";

import "./styles.css";
import Maiar from "../../assets/images/logo/maiar.png";

const LoginPage = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { WalletConnectLoginButton } = DappUI;

  const isLoggedIn = getIsLoggedIn();
  useEffect(() => {
    if (isLoggedIn) {
      console.log("loggg", isLoggedIn);
      // localStorage.removeItem("persist:dapp-core-store");
      const user =
        localStorage.getItem("token") &&
        JSON.parse(localStorage.getItem("token"));
      if (user) {
        // window.location.href = routes.home;
        navigate(routes.home);
      }
    }
  }, [isLoggedIn]);

  const loginHandler = async (oldId, apiKey, wefftWalletAddress) => {
    try {
      setLoader(true);
      const response = await login({
        oldId,
        apiKey,
      });
      if (response) {
        setLoader(false);
        // console.log("Login Response => ", response.data);
        localStorage.setItem(
          "token",
          JSON.stringify(response.headers.authorization)
        );
        localStorage.setItem("wefftWalletAddress", wefftWalletAddress);
        localStorage.setItem("maiarAddress", wefftWalletAddress);
        localStorage.setItem("footerIndex", 1);
        localStorage.setItem("itemIndex", 0);
        localStorage.setItem("oldId", oldId);
        navigate(routes.home);
        // window.open(routes.home);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: "column",
        cursor: 'pointer'
      }}
      onClick={() => window.open('https://docs.google.com/spreadsheets/d/1wTIfiefQjVHfVw3_3L8AaiQAydh8Edycw0NtCDZYTlk/edit?fbclid=IwAR2FyY9JOsIg1SVLwv9Xlxh1LzbuL88pSKJNlDLgHRH821z1TzEIEvS5cv0#gid=0', "_blank")}
    >
      <img
        src={UnderMaintenance}
        style={{
          width: 405,
          height: 720,
          objectFit: "cover",
        }}
      />
    </div>
  );
};

export default LoginPage;
